import type {Middleware} from '@reduxjs/toolkit'
import {isRejectedWithValue} from '@reduxjs/toolkit'

export const QueryMiddleware: Middleware =
    () => (next) => (action) => {
        if (isRejectedWithValue(action)) {
            if (action.payload.status === 403) {
                localStorage.clear()
                window.location.reload()
            }
            console.warn('We got a rejected action!')
        }
        return next(action)
    }