import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiEnum } from "../../common/enums/ApiEnum";
import { PriceBookInterface } from "../../common/interfaces/PriceBookInterface";
import { apiBaseQuery } from "../../common/utils/apiBaseQuery";

interface PricebookPageInterface {
    loading: boolean;
}

const initialState: PricebookPageInterface = {
    loading: false,
};

const priceBookSlice = createSlice({
    name: 'PriceBook',
    initialState,
    reducers: {},
});

export const priceBookApiSlice = createApi({
    reducerPath: "priceBookApi",
    baseQuery: apiBaseQuery(),
    tagTypes: [],
    endpoints: (builder) => ({
        getPriceBook: builder.query<PriceBookInterface[], void>({
            query: () => ApiEnum.PRICE_BOOKS
        }),
    }),
});

export const PriceBookReducer = priceBookSlice.reducer;
export const { useGetPriceBookQuery, useLazyGetPriceBookQuery } = priceBookApiSlice;
