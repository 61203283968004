import {CategoryListInterface} from "../../interfaces/CategoryListInterface";
import {Translate} from "../../utils/Translate";
import {useState} from "react";
import {useAppSelector} from "../../../app/hooks";
import {ProductListItem} from "./ProductListItem";
import {ProductGridItem} from "./ProductGridItem";
import {useGetUserInfoQuery} from "../../../app/rootReducer";

interface Props {
    child: CategoryListInterface;
    disableCheckbox?: boolean;
}

export const CategorySubItem = (props: Props) => {
    const {child} = props;
    const {products, children} = child;

    const [active, setActive] = useState(false)

    const userData = useGetUserInfoQuery()
    const {activeLanguage} = userData?.data || {activeLanguage: null}

    const {gridLayout} = useAppSelector(state => state.productRange);

    if ((!!children && children.length > 0 && children.some(child => child.products && child.products.length > 0)) || (!!products && products.length > 0)) {
        return (
            <div
                className={"c-products-list__sub-item " + (active ? 'is-active ' : 'in-active ') + (!!children && children.length > 0 ? 'c-products-list__sub-item--active-white' : '')}>
                <div className="c-products-list__sub-item__header" onClick={() => setActive(!active)}>
                    <div className="c-products-list__sub-item__header__title">
                        <img src="/images/arrow-black.svg" alt="arrow"/>
                        {!!activeLanguage &&
                            <span>{Translate(child.label, activeLanguage)}</span>
                        }
                    </div>
                    <div className="c-products-list__sub-item__header__icon">
                        <span></span>
                        <span></span>
                    </div>
                </div>

                {!!children && children.length > 0 ? (
                    <div className="c-products-list__sub-items">
                        {children.map((child) => (
                            <CategorySubItem key={'category-' + child.id} child={child}
                                             disableCheckbox={props.disableCheckbox}/>
                        ))}
                    </div>
                ) : (
                    <div className="c-products-list__sub-items" style={{padding: 0}}>
                        <div className={"c-products-list__sub-item " + (active ? "is-active" : "in-active")}
                             style={{margin: 0}}>
                            {!!products && products.length > 0 &&
                                <div
                                    className={"c-products-list__sub-item__items " + (!!gridLayout ? 'c-products-list__sub-item__items--grid' : '')}>
                                    {products.map((product) => {
                                        if (!!gridLayout) {
                                            return (
                                                <ProductGridItem key={'grid-' + product.id} product={product}
                                                                 disableCheckbox={props.disableCheckbox}/>
                                            )
                                        }
                                        return (
                                            <ProductListItem key={'list-' + product.id} product={product}
                                                             disableCheckbox={props.disableCheckbox}/>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return null;
}




