import {StringTranslation} from "../../utils/StringTranslation";
import QRCode from "react-qr-code";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../../app/rootReducer";
import {
    useGetAuthSettingsQuery,
} from "../../../pages/accountPage/AccountSlice";
import {useEffect, useState} from "react";
import {SettingsAuthenticationInput} from "./SettingsAuthenticationInput";
import {SettingsSendCode} from "./SettingsSendCode";
import {TwoFactorTypeEnum} from "../../enums/TwoFactorTypeEnum";

export const SettingsTab = () => {

    const userData = useGetUserInfoQuery()
    const {activeLanguage, phoneNumber} = userData?.data || {activeLanguage: null}

    const {data: translations, isLoading: isTranslationsLoading} = useGetTranslationsQuery();
    const {data: authSettings, isLoading: isLoadingAuthSettings} = useGetAuthSettingsQuery();

    const [selectedNewMethod, setSelectedNewMethod] = useState<string | null>(null)
    const [authCodeInput, setAuthCodeInput] = useState<string>('')
    const [authMethodIsSet, setAuthMethodIsSet] = useState<boolean>(false)
    const [authCodeIsSent, setAuthCodeIsSent] = useState<boolean>(false)

    const [phoneNumberInput, setPhoneNumberInput] = useState<any>()

    const {authentication_methods, current_authentication_method} = authSettings || {
        authentication_methods: [],
        current_authentication_method: ''
    }

    const translationsLoaded = !isTranslationsLoading && !!activeLanguage;
    const selectedMethod = authentication_methods.find(method => method.authentication_method === selectedNewMethod)

    useEffect(() => {
        setPhoneNumberInput(phoneNumber)
    }, [phoneNumber]);

    return (
        <>
            <div className="c-account-content c-account-content--settings">

                <div className="c-account-content__header">
                    {translationsLoaded ?
                        <h1>{StringTranslation('settings', translations, activeLanguage)}</h1>
                        :
                        // eslint-disable-next-line jsx-a11y/heading-has-content
                        <h1 className="skeleton skeleton-title"></h1>
                    }
                </div>

                {!authSettings && !isLoadingAuthSettings &&
                    <div className="c-account-content__settings-content">
                        <p>{StringTranslation('2fa-is-disabled', translations, activeLanguage)}</p>
                    </div>
                }

                {!!authSettings &&
                    <div className="c-account-content__settings-content">

                        <div className="c-account-content__settings-container">
                            <div className="c-account-content__column">
                                {translationsLoaded ?
                                    <>
                                        {!!current_authentication_method &&
                                            <span
                                                className="c-account-content__column__text c-account-content__column__text--label">
                                        {StringTranslation('active-2fa-method', translations, activeLanguage)}</span>
                                        }
                                    </>
                                    :
                                    <span
                                        className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
                                }


                                {translationsLoaded && !isLoadingAuthSettings ?
                                    <>
                                        {!!current_authentication_method &&
                                            <span
                                                className="c-account-content__column__text">{current_authentication_method}</span>
                                        }
                                    </>
                                    :
                                    <span className="c-account-content__column__text skeleton skeleton-name"></span>
                                }
                            </div>
                        </div>

                        {!!authentication_methods.length &&

                            <div className="c-account-content__settings-container">
                                <h3>{StringTranslation('choose-another-2fa-method', translations, activeLanguage)}:</h3>

                                <div className="c-account-content__button-container">

                                    {authentication_methods.map((method) => (
                                        <button className="c-btn c-btn--primary"
                                                onClick={() => setSelectedNewMethod(method.authentication_method)}
                                                disabled={method.authentication_method === current_authentication_method}>
                                            <span>{method.label}</span>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        }

                    </div>
                }

            </div>


            {!!selectedMethod &&

                <div className="c-account-content c-account-content--settings-2fa">
                    <div className="c-account-content__header">
                        {translationsLoaded ?
                            <h1>{StringTranslation('set-the-method', translations, activeLanguage).replace('%method%', selectedMethod.label)}</h1>
                            :
                            // eslint-disable-next-line jsx-a11y/heading-has-content
                            <h1 className="skeleton skeleton-title"></h1>
                        }
                    </div>


                    {selectedMethod.authentication_method === TwoFactorTypeEnum.authenticator &&

                        <>
                            <div className="c-account-content__settings-container">
                                {translationsLoaded ?
                                    <>
                                        <h2>{StringTranslation('step-1-download-app', translations, activeLanguage)}</h2>
                                        <p>{StringTranslation('download-an-authenticator-app', translations, activeLanguage)}</p>
                                    </>
                                    :
                                    <>
                                        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                        <h2 className="skeleton skeleton-title"></h2>
                                        <p className="skeleton skeleton-text"></p>
                                    </>
                                }

                            </div>

                            <div
                                className="c-account-content__settings-container c-account-content__settings-container--row">
                                <div className="c-account-content__settings-container__text">
                                    {translationsLoaded ?
                                        <>
                                            <h2>{StringTranslation('step-2-scan-the-qr-code', translations, activeLanguage)}</h2>
                                            <p>{StringTranslation('scan-the-qr-code-with-the-authenticator-app', translations, activeLanguage)}</p>
                                        </>
                                        :
                                        <>
                                            {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                            <h2 className="skeleton skeleton-title"></h2>
                                            <p className="skeleton skeleton-text"></p>
                                        </>
                                    }
                                </div>

                                <QRCode value={selectedMethod?.url ?? ''}/>
                            </div>

                            <div className="c-account-content__settings-container">
                                {translationsLoaded ?
                                    <>
                                        <h2>{StringTranslation('step-3-enter-the-code', translations, activeLanguage)}</h2>
                                        <p>{StringTranslation('enter-the-verification-code-you-receive-from-the-authenticator-app', translations, activeLanguage)}</p>
                                    </>
                                    :
                                    <>
                                        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                        <h2 className="skeleton skeleton-title"></h2>
                                        <p className="skeleton skeleton-text"></p>
                                    </>
                                }

                                <SettingsAuthenticationInput authCodeInput={authCodeInput}
                                                             authMethodIsSet={authMethodIsSet}
                                                             selectedNewMethod={selectedNewMethod}
                                                             setAuthCodeInput={setAuthCodeInput}
                                                             setAuthMethodIsSet={setAuthMethodIsSet}/>

                            </div>
                        </>
                    }

                    {selectedMethod.authentication_method === TwoFactorTypeEnum.email &&
                        <>
                            <div className="c-account-content__settings-container">
                                {translationsLoaded ?
                                    <>
                                        <h2>{StringTranslation('step-1-receive-your-verification-code', translations, activeLanguage)}</h2>
                                        <p>{StringTranslation('receive-your-verification-code-by-email', translations, activeLanguage)}</p>
                                    </>
                                    :
                                    <>
                                        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                        <h2 className="skeleton skeleton-title"></h2>
                                        <p className="skeleton skeleton-text"></p>
                                    </>
                                }

                                <SettingsSendCode selectedNewMethod={selectedNewMethod}
                                                  authCodeIsSent={authCodeIsSent}
                                                  setAuthCodeIsSent={setAuthCodeIsSent}/>
                            </div>

                            <div className="c-account-content__settings-container">
                                {translationsLoaded ?
                                    <>
                                        <h2>{StringTranslation('step-2-enter-the-code', translations, activeLanguage)}</h2>
                                        <p>{StringTranslation('enter-the-verification-code-you-received-by-email', translations, activeLanguage)}</p>
                                    </>
                                    :
                                    <>
                                        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                        <h2 className="skeleton skeleton-title"></h2>
                                        <p className="skeleton skeleton-text"></p>
                                    </>
                                }

                                <SettingsAuthenticationInput authCodeInput={authCodeInput}
                                                             authMethodIsSet={authMethodIsSet}
                                                             selectedNewMethod={selectedNewMethod}
                                                             setAuthCodeInput={setAuthCodeInput}
                                                             setAuthMethodIsSet={setAuthMethodIsSet}/>
                            </div>

                        </>
                    }

                    {selectedMethod.authentication_method === TwoFactorTypeEnum.sms &&
                        <>
                            <div className="c-account-content__settings-container">
                                {translationsLoaded ?
                                    <>
                                        <h2>{StringTranslation('step-1-receive-your-verification-code', translations, activeLanguage)}</h2>
                                        <p>{StringTranslation('receive-your-verification-code-by-sms', translations, activeLanguage)}</p>
                                    </>
                                    :
                                    <>
                                        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                        <h2 className="skeleton skeleton-title"></h2>
                                        <p className="skeleton skeleton-text"></p>
                                    </>
                                }

                                <SettingsSendCode selectedNewMethod={selectedNewMethod}
                                                  phoneNumberInput={phoneNumberInput}
                                                  authCodeIsSent={authCodeIsSent}
                                                  setPhoneNumberInput={setPhoneNumberInput}
                                                  setAuthCodeIsSent={setAuthCodeIsSent}/>
                            </div>

                            <div className="c-account-content__settings-container">
                                {translationsLoaded ?
                                    <>
                                        <h2>{StringTranslation('step-2-enter-the-code', translations, activeLanguage)}</h2>
                                        <p>{StringTranslation('enter-the-verification-code-you-received-by-sms', translations, activeLanguage)}</p>
                                    </>
                                    :
                                    <>
                                        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                                        <h2 className="skeleton skeleton-title"></h2>
                                        <p className="skeleton skeleton-text"></p>
                                    </>
                                }

                                <SettingsAuthenticationInput authCodeInput={authCodeInput}
                                                             authMethodIsSet={authMethodIsSet}
                                                             selectedNewMethod={selectedNewMethod}
                                                             phoneNumberInput={phoneNumberInput}
                                                             setAuthCodeInput={setAuthCodeInput}
                                                             setAuthMethodIsSet={setAuthMethodIsSet}/>
                            </div>

                        </>
                    }

                </div>
            }

        </>
    )
}
