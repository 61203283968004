
export const breakPointsBannerSlider = [
    {width: 200, itemsToShow: 1.2, itemPadding: [1, 10],outerSpacing: 0},
    {width: 600, itemsToShow: 1.5, itemPadding: [1, 10],outerSpacing: 0},
    {width: 768, itemsToShow: 2.2, itemPadding: [1, 10], outerSpacing: 0},
    {width: 980, itemsToShow: 3.2, itemPadding: [1, 10], outerSpacing: 0},
    {width: 1024, itemsToShow: 3.4, itemPadding: [1, 10], outerSpacing: 0},
    {width: 1200, itemsToShow: 3.4, itemPadding: [1, 10], outerSpacing: 0},
];


export const breakPointsRelatedSlider = [
    { width: 1, itemsToShow: 1, itemPadding: [0, 7.5], outerSpacing: -7.5},
    { width: 450, itemsToShow: 2, itemPadding: [0, 7.5], outerSpacing: -7.5},
    { width: 600, itemsToShow: 3, itemPadding: [0, 7.5], outerSpacing: -7.5 },
];

export const breakPointsOlderModelsSlider = [
    { width: 1, itemsToShow: 1, itemPadding: [0, 7.5], outerSpacing: -7.5 },
    { width: 550, itemsToShow: 2, itemPadding: [0, 7.5], outerSpacing: -7.5 },
    { width: 768, itemsToShow: 3, itemPadding: [0, 7.5], outerSpacing: -7.5 },
    { width: 1024, itemsToShow: 4, itemPadding: [0, 7.5], outerSpacing: -7.5 },
    { width: 1440, itemsToShow: 5, itemPadding: [0, 7.5], outerSpacing: -7.5 },
    { width: 1920, itemsToShow: 6, itemPadding: [0, 7.5], outerSpacing: -7.5 }
];
