import Carousel from "react-elastic-carousel";
import {breakPointsRelatedSlider} from "./BreakPointsCarousel";
import {ProductRelatedProductsItem} from "./ProductRelatedProductsItem";
import {useRef} from "react";
import {ProductInterface} from "../../interfaces/ProductInterface";


interface Props {
  products?: ProductInterface[],
  title: string,
}

export const ProductRelatedProducts = (props: Props) => {

  let carouselProductSliderLeftRef: any = useRef();
  const {title, products} = props;

  if (!products || products.length === 0) return null;

  return (
    <div className="c-related-products">
      <div className="c-related-products__header">
        <h3>{title}</h3>
        <div className="c-related-products__header__nav">
          <div
            className="c-related-products__header__nav__button c-related-products__header__nav__button--left"
            onClick={() => carouselProductSliderLeftRef.slidePrev()}
          >
            <img src="/images/arrow-black.svg" alt="arrow-right"/>
          </div>
          <div
            className="c-related-products__header__nav__button c-related-products__header__nav__button--right"
            onClick={() => carouselProductSliderLeftRef.slideNext()}
          >
            <img src="/images/arrow-black.svg" alt="arrow-right"/>
          </div>
        </div>
      </div>
      <div className="c-related-products__slider">
        <Carousel
          ref={ref => (carouselProductSliderLeftRef = ref)}
          isRTL={false}
          showArrows={false}
          pagination={false}
          breakPoints={breakPointsRelatedSlider}
        >

          {products.map((product, index) => (
            <ProductRelatedProductsItem key={index} product={product}/>
            ))}
        </Carousel>
      </div>
    </div>
  )

}
