import React, {Key, useEffect, useState} from "react";
import {
    useGetTranslationsQuery,
    useGetUserInfoQuery,
    useLazyGetUserInfoQuery,
    useSetUserCountryMutation
} from "../../../app/rootReducer";
import {CountryInterface} from "../../interfaces/CountryInterface";
import {useLazyGetProductRangeQuery} from "../../../pages/productRangePage/ProductRangeSlice";
import {useAppSelector} from "../../../app/hooks";
import {SelectedFilterListInterface} from "../../interfaces/SelectedFilterListInterface";
import {ProductTypeEnum} from "../../enums/ProductTypeEnum";
import {StringTranslation} from "../../utils/StringTranslation";
import {useLazyGetPriceBookQuery} from "../../../pages/priceBookPage/PricebookSlice";

interface CountryPickerProps {
    active: boolean;
    setActive: React.Dispatch<React.SetStateAction<boolean>>;
    setLanguageActive: React.Dispatch<React.SetStateAction<boolean>>;
}
export const CountryPicker: React.FC<CountryPickerProps> = ({ active, setActive, setLanguageActive }) => {

    const {selectedFilters, productType} = useAppSelector(state => state.productRange);

    const {data: translations} = useGetTranslationsQuery()
    const [setUserCountryTrigger] = useSetUserCountryMutation()
    const [getUserInfoTrigger] = useLazyGetUserInfoQuery()
    const [getProductRangeTrigger] = useLazyGetProductRangeQuery()
    const [getPriceBookTrigger] = useLazyGetPriceBookQuery()

    const {data} = useGetUserInfoQuery()
    const {countries, activeLanguage, activeCountry} = data || {countries: [], activeLanguage: null, activeCountry: null}

    const [activeCountryState, setActiveCountryState] = useState<CountryInterface | null>(activeCountry)

    useEffect(() => {
        setActiveCountryState(activeCountry)
    }, [activeCountry])

    if (countries && countries.length > 1 && activeCountryState) {
        return (
            <div className="c-language-picker__wrapper c-language-picker__wrapper--country">
                <div className="c-language-picker__label">
                    <span>{StringTranslation('country', translations, activeLanguage)}:</span>
                </div>
                <div className={"c-language-picker c-language-picker--country " + (active ? "is-active" : "")}>
                    <button className="c-language-picker__btn" onClick={() => openCountryPicker(active, setActive, setLanguageActive)}>
                        <div><span>{activeCountryState.name}</span></div>
                        <img className="img-arrow" src="/images/arrow-down.svg" alt=""/>
                    </button>
                    <div className="c-language-picker__container">
                        <ul>
                            {countries.filter((country: CountryInterface) => country.id !== activeCountryState.id).map((country: CountryInterface, index: Key | null | undefined) => (
                                <li key={index} onClick={() => {
                                    setActive(!active)
                                    setCountry(country, productType, selectedFilters, getUserInfoTrigger, setUserCountryTrigger, setActiveCountryState, getProductRangeTrigger, getPriceBookTrigger)
                                }}><span>{country.name}</span></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }
}

const openCountryPicker = (active: boolean, setActive: any, setLanguageActive: any) => {
    setActive(!active)
    setLanguageActive(false)
}

const setCountry = (country: CountryInterface, productType: ProductTypeEnum, selectedFilters: SelectedFilterListInterface[], getUserInfoTrigger: any, setUserLanguageTrigger: any, setActiveLanguageState: any, getProductRangeTrigger: any, getPriceBookTrigger: any) => {
    setActiveLanguageState(country)
    setUserLanguageTrigger(country.id).then(() => {
        getUserInfoTrigger()
        getProductRangeTrigger({filters: selectedFilters, type: productType})
        getPriceBookTrigger()
    })
}
