import {createSlice} from '@reduxjs/toolkit'
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {apiBaseQuery} from "../../common/utils/apiBaseQuery";
import {ApiEnum} from "../../common/enums/ApiEnum";
import {AuthenticationSettingsInterface} from "../../common/interfaces/AuthenticationSettingsInterface";

interface AccountInterface {}
const initialState: AccountInterface = {}

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {

    },
})

export const accountApiSlice = createApi({
    reducerPath: "accountApi",
    baseQuery: apiBaseQuery(),
    tagTypes: [],
    endpoints: (builder) => ({
        getAuthSettings: builder.query<AuthenticationSettingsInterface, void>({
            query: () => ApiEnum.AUTH_SETTINGS
        }),
        setAuthMethod: builder.mutation<void, {authentication_method: string, code: string, phone_number: string | null}>({
            query: (params) => ({
                url: ApiEnum.AUTH_SETTINGS,
                method: 'PATCH',
                body: {
                    authentication_method: params.authentication_method,
                    code: params.code,
                    phone_number: params.phone_number
                }
            }),
        }),
        sendAuthCode: builder.mutation<void, {authentication_method: string, phone_number: string | null}>({
            query: (params) => ({
                url: ApiEnum.AUTH_SETTINGS + '/send-code',
                method: 'POST',
                body: {
                    authentication_method: params.authentication_method,
                    phone_number: params.phone_number
                }
            }),
        }),
    }),
});

export const accountReducer = accountSlice.reducer

export const {
    useGetAuthSettingsQuery,
    useLazyGetAuthSettingsQuery,
    useSetAuthMethodMutation,
    useSendAuthCodeMutation
} = accountApiSlice;
